import { Avatar, Button, ConfirmDialog, Dropdown, Input, Text, Tooltip } from "@fscrypto/ui";
import { Link, useNavigate } from "@remix-run/react";
import { Code2Icon, CopyIcon, GitForkIcon, MoreVerticalIcon, MoveIcon, PlayIcon, TrashIcon } from "lucide-react";
import { ReactNode, useEffect, useState } from "react";
import { $path } from "remix-routes";
import { useCopyWorkItem } from "~/features/copy-work-item/actor";
import { useCurrentUser } from "~/features/current-user";
import { FileTreeIcon } from "~/features/file-explorer/components/file-explorer-row/file-tree-icon";
import { openMoveItemModal } from "~/features/move-work-item/machines/move-work-item-machine";
import { workItemFactory } from "~/features/studio-2/work-item/state/work-item";
import { useProfile } from "~/state/entities/profile";
import { formatDateTimeAgo } from "~/utils/helpers";
import { tracking } from "~/utils/tracking";
import { useQuery } from "../../state/query";
import { useQueryCodeEditor } from "../../state/query-code-editor";
import { useSelectAndRun } from "../../state/query-ephemeral-runs";
import { setAuthModalOpen } from "../query-public/query-public-container";

const Root = ({ leftSlot, rightSlot, isPublic }: { leftSlot: ReactNode; rightSlot: ReactNode; isPublic?: boolean }) => {
  return (
    <div className="bg-background relative flex h-14 items-center border-y px-2" data-testid="query-toolbar">
      <div className="mr-4 flex flex-1 items-center">{leftSlot}</div>
      <div className="flex items-center gap-x-2">{rightSlot}</div>
    </div>
  );
};

const Title = ({ disabled = false, queryId }: { disabled?: boolean; queryId: string }) => {
  const query = useQuery(queryId);

  if (disabled)
    return (
      <Text size="lg" weight={"semi"}>
        {query?.query.name}
      </Text>
    );

  return (
    <div className="my-1 flex-1">
      <Input
        disabled={disabled}
        value={query?.query.name ?? ""}
        inputClassName="border-none hover:ring-border hover:ring-1 shadow-none hover:shadow text-lg font-semibold max-w-[600px]"
        onChange={(e) => {
          query?.updateName?.(e.target.value);
          tracking("editor_rename_query", "Dashboard Beta", { queryId });
        }}
      />
    </div>
  );
};

const Icon = ({ queryId }: { queryId: string }) => {
  return <FileTreeIcon type="query" size="lg" />;
};

const Fork = ({ queryId, showLabel }: { queryId: string; showLabel?: boolean }) => {
  const query = useQuery(queryId);
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  if (!query) return null;
  return (
    <Tooltip content="Fork Query" side="top">
      <Button
        variant="secondary"
        iconOnly={showLabel ? false : true}
        size="sm"
        onClick={async () => {
          if (!currentUser) {
            setAuthModalOpen();
            return;
          }
          const forked = await query?.fork();
          if (forked) navigate($path("/studio/queries/:id", { id: forked.id }));
          tracking("editor_fork_query", "Dashboard Beta");
        }}
      >
        <GitForkIcon size={18} />
        {showLabel && <span>Fork</span>}
      </Button>
    </Tooltip>
  );
};

const Format = ({ queryId }: { queryId: string }) => {
  const query = useQuery(queryId);
  if (!query) return null;
  return (
    <Tooltip content="Format Query" side="top">
      <Button
        variant="secondary"
        iconOnly
        size="sm"
        onClick={() => {
          query.format();
          tracking("editor_format_query", "Dashboard Beta");
        }}
      >
        <Code2Icon size={18} />
      </Button>
    </Tooltip>
  );
};

const RunButton = ({ queryId }: { queryId: string }) => {
  const query = useQuery(queryId);
  const queryCodeEditor = useQueryCodeEditor();
  const { executeSelectAndRun } = useSelectAndRun(queryId);
  const handleRun = () => {
    query?.resizeIfClosed();
    if (queryCodeEditor?.selectionString) {
      executeSelectAndRun(queryCodeEditor.selectionString);
      return;
    }
    query?.run();
    tracking("editor_run_query", "Dashboard Beta");
  };
  return (
    <Button onClick={handleRun} className="text-primary-foreground" size="sm">
      <PlayIcon size={18} className="fill-background" />
      <Text className="text-primary-foreground" weight="semi">
        {queryCodeEditor?.selectionString ? "Run Selection" : "Run Query"}
      </Text>
    </Button>
  );
};

const Owner = ({ queryId }: { queryId: string }) => {
  const query = useQuery(queryId);
  const owner = useProfile(query?.query.profileId);
  if (!query) return null;
  if (!owner) return null;
  return (
    <Link to={owner.profileUrl!} aria-label="user-link" className="mr-6 flex items-center gap-x-2 px-2">
      <Avatar size="sm" src={owner.avatarUrl!} variant="circle" alt={owner.name} />
      <Text color="muted">{owner.name}</Text>
    </Link>
  );
};

export const LastRunDate = ({ queryId }: { queryId: string }) => {
  const query = useQuery(queryId);
  const [now, setNow] = useState<Date>(new Date());

  useEffect(() => {
    if (!query?.query.lastSuccessfulExecutionAt) return undefined;

    const lastRunDate = new Date(query.query.lastSuccessfulExecutionAt);
    const msSinceLastRun = now.getTime() - lastRunDate.getTime();
    let intervalId: NodeJS.Timeout;

    // If last run was less than a minute ago, update every second
    if (msSinceLastRun < 60000) {
      intervalId = setInterval(() => {
        setNow(new Date());
      }, 1000); // Update every second
    }
    // If last run was less than an hour ago, update every minute
    else if (msSinceLastRun < 3600000) {
      intervalId = setInterval(() => {
        setNow(new Date());
      }, 60000); // Update every minute
    }

    return () => clearInterval(intervalId);
  }, [query?.query.lastSuccessfulExecutionAt, now]);

  if (!query?.query.lastSuccessfulExecutionAt) return null;

  return (
    <Text color="muted" size="xs">
      Updated {formatDateTimeAgo(new Date(query.query.lastSuccessfulExecutionAt))}
    </Text>
  );
};

export const Options = ({ queryId }: { queryId: string }) => {
  const query = useQuery(queryId);
  const { openCopyItemModal } = useCopyWorkItem();
  const navigate = useNavigate();
  if (!query) return null;
  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild>
        <Button variant="secondary" iconOnly size="sm">
          <MoreVerticalIcon className=" size-5 text-content/50 flex-shrink-0" />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content className="z-50">
        <Dropdown.Item asChild>
          <button
            className="flex w-full items-center"
            onClick={() =>
              query &&
              openMoveItemModal({
                workItemId: query.query.id,
                collectionId: query.query.queryCollectionId,
                callback: (collectionId: string | null) => {
                  // this returns the new collectionId, use the workTtem factory to update
                  const item = workItemFactory.workItems.get()[query.query.id];
                  if (!item) return;
                  item.move({ ...item.store.get(), parentId: collectionId });
                },
              })
            }
          >
            <MoveIcon className="text-muted-foreground size-4 mr-3" /> Move Query
          </button>
        </Dropdown.Item>
        <Dropdown.Item asChild>
          <ConfirmDialog
            title={`Delete Query`}
            onConfirm={async () => {
              await query.delete?.();
              navigate("/studio");
            }}
            description={`Are you sure you want to remove ${query?.query.name} and all its contents?`}
            ref={{ current: null }}
          >
            <Button variant="ghost" className="w-full justify-start">
              <TrashIcon className="text-muted-foreground size-4 mr-3" /> Delete Query
            </Button>
          </ConfirmDialog>
        </Dropdown.Item>
        <Dropdown.Item asChild>
          <Button
            variant="ghost"
            onClick={() =>
              query &&
              openCopyItemModal?.({
                workItemId: query.query.id,
                typename: "query",
                name: query.query.name,
              })
            }
          >
            <CopyIcon className="text-muted-foreground size-4 mr-3" /> Copy to Workspace
          </Button>
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};

export const QueryToolbar = { Root, Title, Fork, Format, RunButton, Owner, Icon, LastRunDate, Options };
